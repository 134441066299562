













import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { PostListByCategoryViewModel } from '../../viewmodels/post-list-by-category-viewmodel'

@Observer
@Component({
  components: {
    observer: () => import('vue-intersection-observer'),
  },
})
export default class extends Vue {
  @Inject() vm!: PostListByCategoryViewModel

  loadMore(entry) {
    if (this.vm.reviewCanLoadMore) {
      if (entry.isIntersecting) this.vm.loadMore()
    }
  }
}
